import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import BackgroundImage from "gatsby-background-image";

/**
 * In this functional component a fullscreen <BackgroundImage />  is created.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components.
 * @return {*}
 * @constructor
 */
const FullBackground = ({ className, children }) => {
	const { desktop } = useStaticQuery(
		graphql`
			query {
				desktop: file(relativePath: { eq: "mett.jpg" }) {
					childImageSharp {
						fluid(quality: 100, maxWidth: 2048) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		`
	);

	// Single Image Data
	const imageData = desktop.childImageSharp.fluid;

	return (
		<StyledFullScreenWrapper>
			<BackgroundImage
				Tag="section"
				className={className}
				fluid={imageData}
				backgroundColor={`#040e18`}
				id="fullscreenbg"
				role="img"
				preserveStackingContext={true}
			>
				{children}
			</BackgroundImage>
		</StyledFullScreenWrapper>
	);
};

const StyledFullBackground = styled(FullBackground)`
	min-height: 100%;
	background-attachment: fixed;
	background-position: right;
	background-repeat: no-repeat;
	background-size: cover;
`;
export const StyledFullScreenWrapper = styled.div`
	width: 100%;
	height: 100vh;
`;

export default StyledFullBackground;
